import React from 'react';
import { colors } from '../../../styles/atoms/colors';
import Icon from '../../../styles/atoms/icons';
import { DropdownItem, FormControlStyles, SelectStyles } from './Select.styles';

const Select = ({
  dispatchCase,
  items = [],
  menuItemSize,
  multiple,
  placeholder,
  setValue,
  value,
  ...props
}) => {
  const handleChange = event => {
    dispatchCase
      ? setValue({ type: dispatchCase, payload: event.target.value })
      : setValue(event.target.value);
  };

  return (
    <FormControlStyles variant="outlined" size="small" fullWidth multiple>
      <SelectStyles
        displayEmpty
        IconComponent={iconProps => (
          <Icon
            id="FiChevronDown"
            size={24}
            color={colors.primary[500]}
            {...iconProps}
          />
        )}
        MenuProps={{
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left',
          },
          getContentAnchorEl: null,
          transformOrigin: {
            vertical: 'top',
            horizontal: 'left',
          },
        }}
        multiple={multiple}
        onChange={handleChange}
        renderValue={val =>
          multiple
            ? val.length === 0
              ? placeholder
              : val.length > 1
              ? 'Multiple selected'
              : val[0]
            : !val
            ? placeholder
            : val
        }
        value={value || ''}
        {...props}
      >
        {items.map(item => (
          <DropdownItem
            key={item.id || item.heading}
            value={item.id || item.heading}
            size={menuItemSize || 'medium'}
          >
            {item.heading}
            <Icon id="FiCheck" size={20} />
          </DropdownItem>
        ))}
      </SelectStyles>
    </FormControlStyles>
  );
};

export default Select;
