/**
 *
 * @param {number} totalPage - Total number of pages
 * @param {number} currentPage - The current paginated page
 * @param {boolean} isBreak - Whether or not the component is smaller
 * @returns Array of page numbers
 */

export const getPaginationArray = (totalPage, currentPage, isBreak = false) => {
  const fromFirst = currentPage - 1 < 2 ? currentPage : 0;
  const fromLast =
    totalPage - currentPage < 2 ? totalPage - currentPage + 1 : 0;
  const fromTotal = fromFirst + fromLast;

  let differential = fromTotal === 1 ? 2 : 1;
  differential = fromTotal ? differential : 0;

  let smallScreenDifferential = isBreak ? 2 : 0;
  const middlePageDifferential = isBreak ? 1 : 0;
  smallScreenDifferential = fromTotal
    ? smallScreenDifferential
    : middlePageDifferential;

  let pageArray = [...new Array(totalPage + 1).keys()];

  pageArray.shift();

  removeNumbersFromBeginning(
    pageArray,
    currentPage,
    differential,
    smallScreenDifferential,
    totalPage
  );

  removePagesBeforeCurrent(
    pageArray,
    currentPage,
    totalPage,
    smallScreenDifferential
  );

  removeNumbersFromEnd(
    pageArray,
    currentPage,
    totalPage,
    differential,
    smallScreenDifferential
  );

  pageArray = pageArray.map((page, index) => {
    return {
      id: page,
      key: (index / (pageArray.length - 1)) * 10,
      label: page,
      toPage: page,
    };
  });

  return pageArray;
};

const removeNumbersFromBeginning = (
  pageArray,
  currentPage,
  differential,
  smallScreenDifferential,
  totalPage
) => {
  pageArray.splice(
    currentPage + 1 + differential - smallScreenDifferential,
    totalPage - currentPage - 2 - differential + smallScreenDifferential
  );
};

const removePagesBeforeCurrent = (
  pageArray,
  currentPage,
  totalPage,
  smallScreenDifferential
) => {
  currentPage > 3 &&
    currentPage < totalPage - 1 &&
    pageArray.splice(1, currentPage - 3 + smallScreenDifferential);
};

const removeNumbersFromEnd = (
  pageArray,
  currentPage,
  totalPage,
  differential,
  smallScreenDifferential
) => {
  currentPage >= totalPage - 1 &&
    pageArray.splice(
      1,
      currentPage - differential - 3 + smallScreenDifferential
    );
};
