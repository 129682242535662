import styled, { css } from 'styled-components';

import { colors } from '../../../styles/atoms/colors';
import { font } from '../../../styles/atoms/typography';

export const PaginationLinkStyles = styled.a`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  ${font('text', 'lg', '700')};
  color: ${colors.gray[900]};
  padding: 6px 13.5px;
  border-radius: 4px;
  background-color: transparent;
  cursor: pointer;
  transition: 0.2s;

  ${props =>
    props.disabled
      ? css`
          color: ${colors.gray[500]};
          cursor: not-allowed;

          &:hover {
            text-decoration: none;
            color: ${colors.gray[500]};
          }
        `
      : css`
          &:hover {
            background-color: ${colors.primary[100]};
            color: ${colors.primary[400]};
            fill: ${colors.primary[400]};
          }
        `}

  &.active {
    background-color: ${colors.primary[100]};
    color: ${colors.primary[500]};
  }

  svg {
    transform: rotate(${props => (props.first ? 90 : -90)}deg);
  }
`;
