import OutlinedInput from '@material-ui/core/OutlinedInput';
import styled from 'styled-components';
import { colors } from '../../../styles/atoms/colors';
import shadow from '../../../styles/atoms/shadows';

export const SearchStyles = styled(OutlinedInput)`
  && {
    display: inline-flex;
    width: 100%;
    background-color: ${colors.base.white};
    border-radius: 8px;
    ${shadow('xs')};

    svg {
      margin: 10px;
    }

    svg.clear {
      cursor: pointer;
    }

    input {
      border: 0;
      width: 100%;
      margin: 0;
      display: block;
      background: none;
      box-sizing: content-box;
      -webkit-tap-highlight-color: transparent;
    }
  }
`;
