import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import styled from 'styled-components';
import { colors } from '../../../styles/atoms/colors';
import { font } from '../../../styles/atoms/typography';

export const FormControlStyles = styled(FormControl)``;

export const SelectStyles = styled(Select)`
  && {
    ${font('text', 'md', '400')};
    background-color: ${props =>
      props.disabled ? colors.gray[100] : colors.base.white};
    border-radius: 6px;
    overflow: hidden;
    color: ${props =>
      props.value === '' ||
      (props.multiple && Array.isArray(props.value) && props.value.length === 0)
        ? colors.gray[600]
        : colors.gray[900]};

    svg {
      margin-right: 12px;
    }

    .MuiSelect-root svg {
      display: none;
    }
  }
`;

export const DropdownItem = styled(MenuItem)`
  && {
    ${font('text', 'sm', '400')};
    justify-content: space-between;
    gap: 12px;
    align-items: center;
    padding: ${props => (props.size === 'small' ? '12px 24px' : '24px')};

    svg {
      color: ${colors.primary[500]};
    }

    &:not(.Mui-selected) svg {
      display: none;
    }

    &.Mui-selected {
      background-color: ${colors.base.heroSurface};
      font-weight: 700;
    }
  }
`;
