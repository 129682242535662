import React from 'react';

const CarrotIcon = () => (
  <svg
    fill="currentColor"
    height="7"
    viewBox="0 0 9 7"
    width="9"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="m5.44768 6.47063c-.4003.52001-1.18451.52001-1.58481 0l-3.164375-4.11064c-.506197-.65756-.037433-1.60999.792405-1.60999l6.32875.000001c.82983 0 1.2986.952429.7924 1.609999z" />
  </svg>
);

export default CarrotIcon;
