import styled from 'styled-components';
import { colors } from '../../../../styles/atoms/colors';
import { font } from '../../../../styles/atoms/typography';

export const Heading = styled.h2`
  ${font('display', 'md', '700')};
  color: ${colors.gray[900]};
`;

export const CardContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));
  gap: 30px 32px;
`;

export const NoResults = styled.div`
  ${font('text', 'lg', '700')}
  color: ${colors.gray[600]};
`;
