import FormControl from '@material-ui/core/FormControl';
import React, { useEffect, useRef, useState } from 'react';
import Icon from '../../../styles/atoms/icons';
import { SearchStyles } from './Search.styles';

const Search = ({
  placeholder,
  size,
  searchValue,
  setSearchValue,
  setSearchReducer = 'SET_SEARCH',
}) => {
  const [search, setSearch] = useState('');
  const timeout = useRef();

  const handleClick = () => {
    setSearch('');
    setSearchValue({ type: setSearchReducer, payload: '' });
  };

  const handleChange = event => {
    clearTimeout(timeout.current);
    setSearch(event.target.value);

    timeout.current = setTimeout(
      setSearchValue.bind(null, {
        type: setSearchReducer,
        payload: event.target.value,
      }),
      500
    );
  };

  useEffect(() => {
    setSearch(searchValue);
  }, [searchValue]);

  return (
    <FormControl size={size || 'medium'} fullWidth>
      <SearchStyles
        value={search}
        onChange={event => handleChange(event)}
        startAdornment={<Icon id="FiSearch" />}
        endAdornment={
          searchValue ? (
            <div className="clear" onClick={handleClick}>
              <Icon id="FiX" size={20} />
            </div>
          ) : (
            ''
          )
        }
        placeholder={placeholder || 'Search...'}
      />
    </FormControl>
  );
};

export default Search;
