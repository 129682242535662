import React from 'react';
import { PaginationLinkStyles } from './PaginationLink.styles';

const PaginationLink = ({
  children,
  currentPage,
  disabled,
  first,
  goPage,
  toPage
}) => {
  const handleClick = event => {
    event.preventDefault();
    goPage(toPage);
  };

  return (
    <PaginationLinkStyles
      onClick={handleClick}
      className={`${toPage === currentPage ? 'active' : ''}`}
      first={first}
      disabled={disabled}
    >
      {children}
    </PaginationLinkStyles>
  );
};

export default PaginationLink;
