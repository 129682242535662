import styled from 'styled-components';
import { SelectStyles } from '../../../../molecules/Inputs/Select/Select.styles';
import { atMinWidth } from '../../../../styles/atoms/breakpoints';
import { colors } from '../../../../styles/atoms/colors';
import { font } from '../../../../styles/atoms/typography';

export const FiltersWrapper = styled.div`
  display: flex;
  flex-flow: column-reverse nowrap;
  align-items: center;
  justify-content: space-between;

  ${atMinWidth.lg`
    flex-direction: row;
]  `}
`;

export const FilterClear = styled.p`
  ${font('text', 'md', '400')};
  color: ${colors.primary[500]};
  white-space: nowrap;
  cursor: pointer;
`;

export const SelectWrapper = styled.div`
  display: flex;
  flex-flow: column nowrap;
  align-items: flex-start;
  gap: 16px;
  width: 100%;

  ${SelectStyles} {
    min-width: 100%;

    ${atMinWidth.md`
      min-width: 270px;
    `}
  }

  ${atMinWidth.md`
    flex-direction: row;
    align-items: center;
  `}

  ${atMinWidth.lg`
    width: unset;
  `}
`;

export const SearchContainer = styled.div`
  width: 100%;
  margin-bottom: 16px;

  ${atMinWidth.lg`
    width: auto;
    max-width: 320px;
    margin-bottom: 0;
  `}
`;
