import React, { useEffect } from 'react';

import Search from '../../../../molecules/Inputs/Search/Search';
import Select from '../../../../molecules/Inputs/Select/Select';

import {
  FilterClear,
  FiltersWrapper,
  SearchContainer,
  SelectWrapper,
} from './ResourceFilters.styles';

const ResourceFilters = ({ state, dispatch, resources }) => {
  useEffect(() => {
    const resourceTags = new Set();
    const shopTypeTags = new Set();
    const tags = [];
    const shopTags = [];

    const getTagTitle = value => {
      switch (value) {
        case 'eBook':
          return 'eBook';

        case 'pdf resource':
          return 'PDF Resource';

        default:
          return value
            .split(' ')
            .map(word => word[0].toUpperCase() + word.substring(1))
            .join(' ');
      }
    };

    resources?.map(resource => {
      resource.tags.map(tag => {
        const tagTitle = getTagTitle(tag.name);
        const isShopType =
          tag.id.includes('DatoCmsTag') || tag.id.includes('blog-industry-tag');

        const isFirstLevel = tag?.level === 1;

        isShopType
          ? shopTypeTags.add(tagTitle)
          : isFirstLevel && resourceTags.add(tagTitle);
      });
    });

    [...resourceTags]
      .sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase()))
      .map(tag => {
        tags.push({
          id: tag,
          heading: tag,
        });
      });

    [...shopTypeTags].sort().map(tag => {
      shopTags.push({
        id: tag,
        heading: tag,
      });
    });

    dispatch({ type: 'SET_RESOURCE_TYPES', payload: tags });
    dispatch({ type: 'SET_SHOP_TYPES', payload: shopTags });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleClick = () => {
    dispatch({ type: 'CLEAR' });
  };

  return (
    <FiltersWrapper>
      <SelectWrapper>
        <Select
          items={state.resourceTypes}
          value={state.resourceType}
          setValue={dispatch}
          dispatchCase="SET_RESOURCE_TYPE"
          placeholder="Filter by Resource Type"
          menuItemSize="small"
        />
        {(state.search || state.shopType || state.resourceType) && (
          <FilterClear onClick={handleClick}>Clear Filters</FilterClear>
        )}
      </SelectWrapper>
      <SearchContainer>
        <Search
          size="small"
          searchValue={state.search}
          setSearchValue={dispatch}
        />
      </SearchContainer>
    </FiltersWrapper>
  );
};

export default ResourceFilters;
