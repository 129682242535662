import React, { useRef } from 'react';
import CarrotIcon from '../../images/carrot';
import useResponsiveBreakpoints from '../../utils/hooks/useResponsiveBreakpoints';
import { PaginationStyles } from './Pagination.styles';
import PaginationLink from './PaginationLink/PaginationLink';
import { getPaginationArray } from './utils/getPaginationArray';
import { breakpoints } from '../../styles/atoms/breakpoints';

function Pagination({ totalPage, currentPage, goPage }) {
  const paginationContainerRef = useRef('');
  const containerBreakpoints = useResponsiveBreakpoints(
    paginationContainerRef,
    breakpoints
  );
  const leftIndentation = 3;
  const rightIndentation = 2;
  const pageArray = getPaginationArray(
    totalPage,
    currentPage,
    parseFloat(breakpoints[containerBreakpoints]) < parseFloat(breakpoints.lg)
  );
  const additionalItems = [
    {
      id: 'firstSpread',
      key: 1,
      label: '...',
      toPage: currentPage - 2 > 1 ? currentPage - 3 : 1,
    },
    {
      id: 'lastSpread',
      key: 9,
      label: '...',
      toPage: currentPage + 2 < totalPage ? currentPage + 3 : totalPage,
    },
  ];

  const modifiedPageArray = [...pageArray, ...additionalItems].sort(
    (prev, next) => {
      return prev.key - next.key;
    }
  );

  return (
    <PaginationStyles
      ref={paginationContainerRef}
      componentWidth={parseFloat(breakpoints[containerBreakpoints])}
    >
      <PaginationLink
        currentPage={currentPage}
        toPage={currentPage - 1}
        goPage={goPage}
        first
        disabled={currentPage === 1}
      >
        <CarrotIcon />
        <span className="link-label">Previous</span>
      </PaginationLink>
      <div className="page-number-container">
        {modifiedPageArray.map(page => {
          if (page.id === 'firstSpread' && currentPage <= leftIndentation) {
            return;
          }
          if (
            page.id === 'lastSpread' &&
            currentPage >= totalPage - rightIndentation
          ) {
            return;
          }
          return (
            <PaginationLink
              key={page.key}
              currentPage={currentPage}
              toPage={page.toPage}
              goPage={goPage}
            >
              {page.label}
            </PaginationLink>
          );
        })}
      </div>
      <PaginationLink
        currentPage={currentPage}
        toPage={currentPage + 1}
        goPage={goPage}
        disabled={currentPage === totalPage}
      >
        <span className="link-label">Next</span>
        <CarrotIcon />
      </PaginationLink>
    </PaginationStyles>
  );
}

export default Pagination;
